import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  createAIMTestAsync,
  getAllAIMTestAsync,
  getAIMTByIdAsync,
  updateAIMTByIdAsync,
  deleteAIMTByIdAsync,
} from "./testaimocktest.async";

const initialState = {
  mockTestLoader: false,
  createMockTest: [],
  getAllMockTest: [],
  getMockTestById: [],
  mockTestUpdate: [],
  mockTestDelete: [],
};

export const testaimocktestSlice = createSlice({
  name: "syllabus",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getAllAIMTestAsync.pending,
        createAIMTestAsync.pending,
        getAIMTByIdAsync.pending,
        updateAIMTByIdAsync.pending,
        deleteAIMTByIdAsync.pending
      ),
      (state) => {
        state.mockTestLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getAllAIMTestAsync.fulfilled),
      (state, action) => {
        state.mockTestLoader = false;
        state.getAllMockTest = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(createAIMTestAsync.fulfilled),
      (state, action) => {
        state.mockTestLoader = false;
        state.createMockTest = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getAIMTByIdAsync.fulfilled),
      (state, action) => {
        state.mockTestLoader = false;
        state.getMockTestById = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(deleteAIMTByIdAsync.fulfilled),
      (state, action) => {
        state.mockTestLoader = false;
        state.mockTestDelete = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateAIMTByIdAsync.fulfilled),
      (state, action) => {
        state.mockTestLoader = false;
        state.mockTestUpdate = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(
        getAllAIMTestAsync.rejected,
        createAIMTestAsync.rejected,
        getAIMTByIdAsync.rejected,
        updateAIMTByIdAsync.rejected,
        deleteAIMTByIdAsync.rejected
      ),
      (state, action) => {
        state.mockTestLoader = false;
      }
    );
  },
  reducers: {
    emptyTestMockTest: (state) => initialState,
  },
});

export const { emptyTestMockTest } = testaimocktestSlice.actions;

export default testaimocktestSlice.reducer;
